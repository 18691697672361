import React from 'react'
import { CardItem } from '../../../utils/CardItem'
import ToolBar from '../../../components/toolbar/ToolBar'

const CheckListViewNew = () => {
  return (
   <>
       <CardItem height="810px">
      <div className="card_container">
        {/* <CardTitle title={selectedLink} type={"project_view"}></CardTitle> */}
        <div style={{ padding: "20px" }}>
          <ToolBar title={"Check List"}></ToolBar>
        </div>

      </div>
    </CardItem>
   </>
  )
}

export default CheckListViewNew