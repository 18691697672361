import React, { useEffect, useRef, useState } from "react";
import { CardItem } from "../../../utils/CardItem";
import CustomBreadcrumbOrg from "../view/CustomBreadCrumbOrg";
import Button from "../../../components/buttons/Button";
import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  IconButton,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import ThreeDotDropdown from "../../../components/dropdown/ThreeDotDropdown";
import {
  CustomTabPanel,
  StyledTab,
  a11yProps,
} from "../../../components/constants/Constants";
import CheckListItemCard from "../view/CheckListItemCard";
import CustomDialog from "../../../components/dialog/CustomDialog";
import BasicModal from "../../../components/modal/Modal";
import { modalStyle } from "../../../components/constants/ConstData";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  createProjectStatuCheckListOrg,
  getPrjectstatusCheckListOrg,
} from "../../../redux/slices/orgSlice";
import PlusIcon from "../../../assets/images/plus";
import CloseIcon from "../../../assets/images/close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Loading from "../../../components/loading/Loading";
import CustomDropDown from "../../../components/customDropDown/CustomDropDown";
import { CHECKLIST_TYPE } from "../../../constants/checkListTypeConstants";
const options = [
  { name: "Edit", value: "Edit" },
  { name: "Delete", value: "Delete" },
];
const WorkFlowChecklist = () => {
  const { id, status_id } = useParams();
  const location = useLocation();
  const { projectStatus } = location.state;
  // const id=20;
  const dispatch = useDispatch();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [currentValue, setCurrentValue] = useState(0);
  const [removedItems, setRemovedItems] = useState([]);
  const navigate = useNavigate();
  const breadcrumbs = [
    <Link key="1" to={`/org/${id}/workflow`}>
      <b>
        {projectStatus.displayName
          ? projectStatus.displayName
          : projectStatus.name}
      </b>
    </Link>,
    <Typography key="2" color="text.primary">
      Check List
    </Typography>,
  ];
  const checkLists = useSelector(
    (state) => state.org.projectStatusCheckListOrg
  );
  const projectStatusCheckListLoading = useSelector(
    (state) => state.org.projectStatusCheckListLoading
  );
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue: setAddFormValue,
    getValues: getAddFormValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      desc: "",
      items: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items", // Name of the field array
  });
  const {
    register: registerEditForm,
    control: controlEditForm,
    handleSubmit: handleSubmitEditForm,
    setValue: setEditFormValue,
    reset: resetEditForm,
    getValues: getEditformValues,
  } = useForm({
    defaultValues: {
      name: "",
      desc: "",
      chklistid: "",
      statusid: "",
      isactive: "",
      // type:CHECKLIST_TYPE.TEXT,
      items: [],
    },
  });
  const {
    fields: fieldsEditForm,
    append: appendEditForm,
    remove: removeEditForm,
  } = useFieldArray({
    control: controlEditForm,
    name: "items",
  });
  const [value, setValue] = useState(0);
  const [openAddCheckListModal, setOpenAddCheckListModal] = useState(false);
  const [openEditCheckListModal, setOpenEditCheckListModal] = useState(false);
  const createData = useSelector((state) => state.form.createData);
  const [selectedTypeAndIndex, setSelectedTypeAndIndex] = useState({
    type: null,
    index: null,
  });
  const [typeChanged, setTypeChanged] = useState(CHECKLIST_TYPE.TEXT);
  const [indexOfTypeChanged, setIndexOfTypeChanged] = useState();
  const [typeChangedEdit, setTypeChangedEdit] = useState(false);
  const [indexOfTypeChangedEdit, setIndexOfTypeChangedEdit] = useState();
  const [itemsArr, setItemsArr] = useState([]);
  let dummyData = [0, 1, 2];
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentValue(newValue);
  };

  const addCheckList = (data) => {
    let sendData = {
      chkdtldtl: [
        {
          name: data.name,
          desc: data.description,
          chklistid: 0,
          statusid: status_id,
          isactive: 1,
          items: data.items.map((item, index) => {
            return {
              id: 0,
              statusid: status_id,
              name: item.name,
              displayname: item.name,
              comments: item.description,
              order: index + 1,
              isdefault: item.isdefault ? 1 : 0,
              isactive: 1,
              type: item.type ? item.type : CHECKLIST_TYPE.TEXT,
              value:
                item.sub_items && item.sub_items.length > 0
                  ? [...item.sub_items.map((i) => i.name)]
                  : null,
            };
          }),
        },
      ],
    };
    dispatch(
      createProjectStatuCheckListOrg({
        url: `/crm/org/${id}/checkList`,
        params: {
          orgId: id,
        },
        data: sendData,
      })
    );
  };
  const editCheckList = (data) => {
    console.log("data", data);
    let sendData = data;
    sendData.items = [...sendData.items, ...removedItems];
    sendData.items = sendData.items.map((item, index) => {
      return {
        ...item,
        isdefault: item.isdefault ? 1 : 0,
        order: index + 1,
        value: item.type === CHECKLIST_TYPE.TEXT ? null : item.sub_items,
      };
    });
    console.log("send data", sendData);
    dispatch(
      createProjectStatuCheckListOrg({
        url: `/crm/org/${id}/checkList`,
        params: {
          orgId: id,
        },
        data: {
          chkdtldtl: [sendData],
        },
      })
    );
  };
  const loadCheckList = () => {
    dispatch(
      getPrjectstatusCheckListOrg({
        url: `/crm/org/${id}/checkList/0/status/${status_id}`,
        params: {
          orgId: id,
          id: 0,
          statusId: status_id,
        },
      })
    );
  };
  const handleSelect = (value) => {
    if (value === "Delete") {
      setOpenDeleteConfirmation(true);
    }
    if (value === "Edit") {
      setEditFormValue("name", checkLists[currentValue].mas_name);
      setEditFormValue("desc", checkLists[currentValue]?.mas_desc);
      setEditFormValue("chklistid", checkLists[currentValue]?.mas_id);
      setEditFormValue("statusid", checkLists[currentValue]?.mas_statusid);
      setEditFormValue("isactive", checkLists[currentValue]?.mas_isactive);

      // checkLists[currentValue].chklist_dtls.map((item,index)=>{
      //   setEditFormValue(`items[${index}].name`, item.mas_name);
      //   setEditFormValue(`items[${index}].description`, item.chk_comments);
      // })
      checkLists[currentValue].chklist_dtls.forEach((item) => {
        let sub_items = null;
        if (item.chk_value !== "" && item.chk_value) {
          sub_items = JSON.parse(item.chk_value);
        }
        appendEditForm({
          name: item.chk_name,
          comments: item.chk_comments,
          displayname: item.chk_displayname,
          id: item.chk_id,
          statusid: item.chk_statusid,
          order: item.chk_order,
          isdefault: item.chk_default,
          isactive: item.chk_isactive,
          type: item.chk_type ? item.chk_type : CHECKLIST_TYPE.TEXT,
          sub_items:sub_items&& sub_items[0] === "" ? null : sub_items,
        });
      });
      setOpenEditCheckListModal(true);
    }
  };
  const deleteCheckList = () => {
    let sendData = {
      chkdtldtl: [
        {
          name: checkLists[currentValue].mas_name,
          description: checkLists[currentValue].description,
          chklistid: checkLists[currentValue].mas_id,
          statusid: status_id,
          isactive: 0,
          items: checkLists[currentValue].chklist_dtls.map((item) => {
            return {
              id: item.chk_id,
              statusid: item.chk_statusid,
              name: item.chk_name,
              displayname: item.chk_displayname,
              comments: item.chk_comments,
              order: item.chk_order,
              isdefault: item.chk_default,
              isactive: item.chk_isactive,
            };
          }),
        },
      ],
    };
    dispatch(
      createProjectStatuCheckListOrg({
        url: `/crm/org/${id}/checkList`,
        params: {
          orgId: id,
        },
        data: sendData,
      })
    );
  };
  const renderItems = () => {
    return (
      <>
        {fields.map((item, index) => {
          return (
            <>
              <div key={item.id} className="item_title">
                <div style={{ display: "flex" }}>
                  <div
                    className="item_title"
                    style={{
                      paddingLeft: "16px",
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "15px",
                    }}
                  >
                    Field {index + 1}
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    {" "}
                    <IconButton
                      sx={{
                        backgroundColor: "transparent !important",
                      }}
                      aria-label="delete"
                      onClick={() => {
                        let temp = [...itemsArr];
                        temp.pop();
                        setItemsArr(temp);
                        remove(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="text_medium">Check List Type</label>
                  <Controller
                    name={`items.${index}.type`}
                    control={control}
                    defaultValue={CHECKLIST_TYPE.TEXT}
                    rules={{ required: "This field is required" }}
                    render={({ field: { onChange, value } }) => (
                      <CustomDropDown
                        options={[
                          {
                            id: "1",
                            value: CHECKLIST_TYPE.TEXT,
                            label: CHECKLIST_TYPE.TEXT,
                          },
                          {
                            id: "2",
                            value: CHECKLIST_TYPE.DROPDOWN,
                            label: CHECKLIST_TYPE.DROPDOWN,
                          },

                          {
                            id: "3",
                            value: CHECKLIST_TYPE.CHECKBOX,
                            label: CHECKLIST_TYPE.CHECKBOX,
                          },
                          // {
                          //   id: "4",
                          //   value: CHECKLIST_TYPE.RADIO_BUTTON,
                          //   label: CHECKLIST_TYPE.RADIO_BUTTON,
                          // },
                          {
                            id: "5",
                            value: CHECKLIST_TYPE.MULTI_SELECT_DROP_DOWN,
                            label: CHECKLIST_TYPE.MULTI_SELECT_DROP_DOWN,
                          },
                        ]}
                        value={value} // Current value of the dropdown
                        onChange={(e) => {
                          setAddFormValue(`items.${index}.type`, e.value);
                          setTypeChanged(e.value);
                          setIndexOfTypeChanged(index);
                        }} // Handle value change
                      />
                    )}
                  />
                  {errors.items?.[index]?.type && (
                    <p className="error_message">
                      {errors.items[index].type.message}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="text_medium">Field Label</label>
                  <input
                    type="text"
                    name="name"
                    {...register(`items.${index}.name`, {
                      required: true,
                    })}
                  />
                  <p className="error_message">{errors.name?.message}</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {/* <label className="text_medium">Value</label> */}
                  {/* {typeChanged === CHECKLIST_TYPE.CHECKBOX &&
                    indexOfTypeChanged === index && (
                      <>
                        <NestedFieldArray
                          items={item.sub_items}
                          control={control}
                          index={index}
                          label={"Checkbox label"}
                        />
                      </>
                    )} */}
                  {typeChanged === CHECKLIST_TYPE.RADIO_BUTTON &&
                    indexOfTypeChanged === index && (
                      <>
                        <NestedFieldArray
                          items={item.sub_items}
                          control={control}
                          index={index}
                          label={"Radio  Button label"}
                        />
                      </>
                    )}
                  {(typeChanged === CHECKLIST_TYPE.DROPDOWN ||
                    typeChanged === CHECKLIST_TYPE.MULTI_SELECT_DROP_DOWN) &&
                    indexOfTypeChanged === index && (
                      <>
                        <NestedFieldArray
                          items={item.sub_items}
                          control={control}
                          index={index}
                          label={"Option"}
                        />
                      </>
                    )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="text_medium">Description</label>
                  <textarea
                    type="text"
                    name="description"
                    rows={4}
                    {...register(`items.${index}.description`, {})}
                  />
                  <p className="error_message">{errors.description?.message}</p>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ marginTop: "15px" }}
                >
                  <label className="text_medium">Check if Mandatory</label>
                  <Controller
                    name={`items.${index}.isdefault`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        sx={{
                          color: "#cdd2e1",
                          "&.Mui-checked": {
                            color: "#189d3d",
                          },
                        }}
                        onChange={(e) => {
                          setAddFormValue(
                            `items.${index}.isdefault`,
                            e.target.checked
                          );
                        }}
                        //value={value}
                      />
                    )}
                  />
                  {errors.items?.[index]?.type && (
                    <p className="error_message">
                      {errors.items[index].isdefault.message}
                    </p>
                  )}
                </Grid>
              </div>
            </>
          );
        })}
        <div
          className="addIcon"
          onClick={(e) => {
            let temp = [...itemsArr];
            temp.push({});
            setItemsArr(temp);
            append({
              name: "",
              displayname: "",
            });
          }}
        >
          <div style={{ cursor: "pointer" }}>
            {" "}
            <IconButton
              aria-label="adding"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </div>
          <div className="flexCenter" style={{ cursor: "pointer" }}>
            Add CheckList Field
          </div>
        </div>
      </>
    );
  };

  const NestedFieldArray = ({ items, control, index, label }) => {
    const {
      fields: subFields,
      append: appendSubItem,
      remove: removeSubItem,
    } = useFieldArray({
      control,
      name: `items.${index}.sub_items`,
    });

    return (
      <div>
        {/* Check if subFields is empty or has less than 1 element */}
        {subFields.length === 0 ? (
          <div key={0} style={{ marginTop: "15px" }}>
            <label className="text_medium">{label} 1</label>
            <div>
              <input
                className="input_item"
                {...register(`items.${index}.sub_items.0.name`)}
              />
            </div>
          </div>
        ) : (
          subFields.map((subItem, subIndex) => (
            <div key={subIndex} style={{ marginTop: "10px" }}>
              <label className="text_medium">
                {label} {subIndex + 1}
              </label>
              <div style={{ display: "flex" }}>
                <input
                  className="input_item"
                  {...register(`items.${index}.sub_items.${subIndex}.name`)}
                />
                <div>
                  <IconButton
                    onClick={() => {
                      removeSubItem(subIndex);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          ))
        )}
        <div
          className="addIcon2"
          onClick={(e) => {
            appendSubItem({});
          }}
        >
          <div style={{ cursor: "pointer" }}>
            {" "}
            <IconButton
              aria-label="adding"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </div>
          {/* <div className="flexCenter" style={{ cursor: "pointer" }}>
            Add item
          </div> */}
        </div>
      </div>
    );
  };

  const renderEditItems = () => {
    return (
      <>
        {fieldsEditForm.map((item, index) => {
          return (
            <>
              <div key={item.id} className="item_title">
                <div style={{ display: "flex" }}>
                  <div
                    className="item_title"
                    style={{
                      paddingLeft: "16px",
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "15px",
                    }}
                  >
                    Field {index + 1}
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    {" "}
                    <IconButton
                      sx={{
                        backgroundColor: "transparent !important",
                        //width:'20px',
                        //height:'10px'
                      }}
                      // className={classes.customHoverFocus}

                      aria-label="delete"
                      onClick={() => {
                        let itemRemoved =
                          checkLists[currentValue].chklist_dtls[index];
                        if (itemRemoved) {
                          let temp = {
                            id: itemRemoved.chk_id,
                            comments: itemRemoved.chk_comments,
                            displayname: itemRemoved.chk_displayname,
                            isactive: 0,
                            isdefault: itemRemoved.chk_default,
                            order: itemRemoved.chk_order,
                            statusid: itemRemoved.chk_statusid,
                          };
                          setRemovedItems([...removedItems, temp]);
                        }
                        let temp = [...itemsArr];
                        temp.pop();
                        setItemsArr([...temp]);
                        removeEditForm(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="text_medium">Check List Type</label>
                  <Controller
                    name={`items.${index}.type`} // Use your appropriate index
                    control={controlEditForm}
                    // defaultValue={CHECKLIST_TYPE.TEXT} // Set a default value if needed
                    rules={{ required: "This field is required" }} // Validation rules
                    render={({ field: { onChange, value } }) => (
                      <CustomDropDown
                        options={[
                          {
                            id: "1",
                            value: CHECKLIST_TYPE.TEXT,
                            label: CHECKLIST_TYPE.TEXT,
                          },
                          {
                            id: "2",
                            value: CHECKLIST_TYPE.DROPDOWN,
                            label: CHECKLIST_TYPE.DROPDOWN,
                          },

                          {
                            id: "3",
                            value: CHECKLIST_TYPE.CHECKBOX,
                            label: CHECKLIST_TYPE.CHECKBOX,
                          },
                          {
                            id: "4",
                            value: CHECKLIST_TYPE.RADIO_BUTTON,
                            label: CHECKLIST_TYPE.RADIO_BUTTON,
                          },
                          {
                            id: "5",
                            value: CHECKLIST_TYPE.MULTI_SELECT_DROP_DOWN,
                            label: CHECKLIST_TYPE.MULTI_SELECT_DROP_DOWN,
                          },
                        ]}
                        defaultValue={getEditformValues(`items.${index}.type`)}
                        value={value} // Current value of the dropdown
                        onChange={(value) => {
                          setEditFormValue(`items.${index}.type`, value.value);
                          setTypeChangedEdit(value.value);
                          setIndexOfTypeChangedEdit(index);
                          console.log("type changed edit", value.value);
                        }} // Handle value change
                      />
                    )}
                  />
                  {errors.items?.[index]?.type && (
                    <p className="error_message">
                      {errors.items[index].type.message}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="text_medium">Field Label</label>
                  <input
                    type="text"
                    name="name"
                    {...registerEditForm(`items.${index}.name`, {
                      required: true,
                    })}
                  />
                  <p className="error_message">{errors.name?.message}</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="text_medium">Description</label>
                  <textarea
                    type="text"
                    name="comments"
                    rows={4}
                    {...registerEditForm(`items.${index}.comments`, {})}
                  />
                  <input
                    type="text"
                    name="id"
                    style={{ display: "none" }}
                    {...registerEditForm(`items.${index}.id`)}
                  />
                  <input
                    type="text"
                    name="statusid"
                    style={{ display: "none" }}
                    {...registerEditForm(`items.${index}.statusid`)}
                  />
                  <input
                    type="text"
                    name="order"
                    style={{ display: "none" }}
                    {...registerEditForm(`items.${index}.order`)}
                  />
                  <input
                    type="text"
                    name="isdefault"
                    style={{ display: "none" }}
                    {...registerEditForm(`items.${index}.isdefault`)}
                  />
                  <input
                    type="text"
                    name="isactive"
                    style={{ display: "none" }}
                    {...registerEditForm(`items.${index}.isactive`)}
                  />
                  <p className="error_message">{errors.description?.message}</p>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="text_medium">Check List Type</label>
                  <Controller
                    name={`items.${index}.type`} // Use your appropriate index
                    control={controlEditForm}
                    // defaultValue={CHECKLIST_TYPE.TEXT} // Set a default value if needed
                    rules={{ required: "This field is required" }} // Validation rules
                    render={({ field: { onChange, value } }) => (
                      <CustomDropDown
                        options={[
                          {
                            id: "1",
                            value: CHECKLIST_TYPE.TEXT,
                            label: CHECKLIST_TYPE.TEXT,
                          },
                          {
                            id: "2",
                            value: CHECKLIST_TYPE.DROPDOWN,
                            label: CHECKLIST_TYPE.DROPDOWN,
                          },

                          {
                            id: "3",
                            value: CHECKLIST_TYPE.CHECKBOX,
                            label: CHECKLIST_TYPE.CHECKBOX,
                          },
                          {
                            id: "4",
                            value: CHECKLIST_TYPE.RADIO_BUTTON,
                            label: CHECKLIST_TYPE.RADIO_BUTTON,
                          },
                          {
                            id: "5",
                            value: CHECKLIST_TYPE.MULTI_SELECT_DROP_DOWN,
                            label: CHECKLIST_TYPE.MULTI_SELECT_DROP_DOWN,
                          },
                        ]}
                        defaultValue={getEditformValues(`items.${index}.type`)}
                        value={value} // Current value of the dropdown
                        onChange={(value) => {
                          setEditFormValue(`items.${index}.type`, value.value);
                          setTypeChangedEdit(value.value);
                          setIndexOfTypeChangedEdit(index);
                          console.log("type changed edit", value.value);
                        }} // Handle value change
                      />
                    )}
                  />
                  {errors.items?.[index]?.type && (
                    <p className="error_message">
                      {errors.items[index].type.message}
                    </p>
                  )}
                </Grid> */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {item.type !== CHECKLIST_TYPE.TEXT ? (
                    <NestedFieldArrayEdit
                      items={item.sub_items}
                      control={control}
                      index={index}
                      label={"Label"}
                      //type={i}
                    />
                  ) : (
                    <></>
                  )}
                  {item.type === CHECKLIST_TYPE.TEXT &&
                  index === indexOfTypeChangedEdit &&
                  typeChangedEdit !== CHECKLIST_TYPE.TEXT ? (
                    <NestedFieldArrayEdit
                      items={item.sub_items}
                      control={control}
                      index={index}
                      label={"Label"}
                      //type={i}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="text_medium">Check if Mandatory</label>
                  <Controller
                    name={`items.${index}.isdefault`}
                    control={controlEditForm}
                    defaultValue={
                      getEditformValues(`items.${index}.isdefault`)
                        ? true
                        : false
                    }
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        checked={
                          getEditformValues(`items.${index}.isdefault`)
                            ? true
                            : false
                        }
                        sx={{
                          color: "#cdd2e1",
                          "&.Mui-checked": {
                            color: "#189d3d",
                          },
                        }}
                        onChange={(e) => {
                          setEditFormValue(
                            `items.${index}.isdefault`,
                            e.target.checked
                          );
                        }}
                        //value={value}
                      />
                    )}
                  />
                  {errors.items?.[index]?.type && (
                    <p className="error_message">
                      {errors.items[index].isdefault.message}
                    </p>
                  )}
                </Grid>
              </div>
            </>
          );
        })}
        <div
          className="addIcon"
          onClick={(e) => {
            let temp = [...itemsArr];
            temp.push({});
            setItemsArr([...temp]);
            appendEditForm({
              id: 0,
              statusid: status_id,
              name: "",
              displayname: "",
              comments: "",
              order: "",
              isdefault: 0,
              isactive: 1,
              type: CHECKLIST_TYPE.TEXT,
              sub_items: [],
            });
          }}
        >
          <div style={{ cursor: "pointer" }}>
            {" "}
            <IconButton
              aria-label="adding"
              onClick={(e) => {
                e.preventDefault();
                // append({
                //   name: "",
                //   description: "",
                // });
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </div>
          <div className="flexCenter" style={{ cursor: "pointer" }}>
            Add item
          </div>
        </div>
      </>
    );
  };

  const NestedFieldArrayEdit = ({ items, control, index, label, type }) => {
    const {
      fields: subFieldsEdit,
      append: appendSubItemEdit,
      remove: removeSubItemEdit,
    } = useFieldArray({
      control: controlEditForm,
      name: `items.${index}.sub_items`,
    });

    if (getEditformValues(`items.${index}.type`) === CHECKLIST_TYPE.TEXT) {
      return <></>;
    } else {
      console.log("subfields.length", subFieldsEdit.length);
      return (
        <div>
          {subFieldsEdit.length === 0 ? (
            <>
              <div key={0} style={{ marginTop: "15px" }}>
                <label className="text_medium">{label} 1</label>
                <div>
                  <input
                    className="input_item"
                    {...registerEditForm(
                      `items.${index}.sub_items.${0}`
                    )}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              
              {subFieldsEdit?.map((subItem, subIndex) => {
                return (
                  <div key={subIndex} style={{ marginTop: "10px" }}>
                    <label className="text_medium">
                      {label} {subIndex + 1}
                    </label>
                    <div style={{ display: "flex" }}>
                      <input
                        name={`name`}
                        className="input_item"
                        {...registerEditForm(
                          `items.${index}.sub_items.${subIndex}`
                        )}
                      />
                      <div>
                        <IconButton
                          onClick={() => {
                            removeSubItemEdit(subIndex);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                );
              })}
              
            </>
          )}

          <div
            className="addIcon2"
            onClick={(e) => {
              appendSubItemEdit();
            }}
          >
            <div style={{ cursor: "pointer" }}>
              {" "}
              <IconButton
                aria-label="adding"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </div>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    if (createData?.createSuccess) {
      setOpenAddCheckListModal(false);
      setOpenEditCheckListModal(false);
      resetEditForm();
      reset();
    }
    loadCheckList();
  }, [createData]);

  const bottomRef = useRef(null);
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView();
    }
  }, [itemsArr]);

  return (
    <CardItem height="810px">
      {projectStatusCheckListLoading ? (
        <Loading />
      ) : (
        <>
          <div style={{ padding: "20px" }}>
            <div className="d_flex justify_content_between">
              <div className="d_flex align_items_center">
                <CustomBreadcrumbOrg breadcrumbs={breadcrumbs} />
              </div>
              {checkLists?.length === 0 && (
                <>
                  <Button
                    type="submit"
                    className="primary_button d_flex align_items_center"
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "18px",
                      padding: "5px 20px",
                    }}
                    leftIcon={<PlusIcon color="#fff" />}
                    onClick={() => {
                      setOpenAddCheckListModal(true);
                    }}
                  >
                    Add CheckList
                  </Button>
                </>
              )}
            </div>
            <div className="">
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="card_content"
              >
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#189d3d",
                    },
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  {checkLists.map((item, index) => {
                    return (
                      <StyledTab
                        icon={<CheckBoxIcon />}
                        iconPosition="start"
                        label={item.mas_name}
                        sx={{
                          fontSize: "10px",
                        }}
                        {...a11yProps(index)}
                      />
                    );
                  })}
                </Tabs>
              </Box>
              <div
                className="card_content"
                style={{
                  height: "575px",
                  overflowY: "scroll",
                  position: "relative",
                }}
              >
                {checkLists?.length > 0 && (
                  <div style={{ position: "absolute", right: 10, top: 10 }}>
                    <ThreeDotDropdown
                      options={options}
                      onSelect={(value) => handleSelect(value)}
                    />
                  </div>
                )}

                {checkLists.map((checklist, index) => {
                  return (
                    <CustomTabPanel value={value} index={index}>
                      <div style={{ padding: "20px" }}>
                        <Grid container>
                          {checklist.chklist_dtls.length > 0 ? (
                            checklist.chklist_dtls?.map((item) => (
                              <Grid item xs={7} sm={7} md={7} lg={7}>
                                <CardItem>
                                  <CheckListItemCard
                                    name={item.chk_name}
                                    description={item.chk_comments}
                                    type={item.chk_type}
                                  />
                                </CardItem>
                              </Grid>
                            ))
                          ) : (
                            <p className="text_center">No Items</p>
                          )}
                        </Grid>
                      </div>
                    </CustomTabPanel>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}

      <CustomDialog
        open={openDeleteConfirmation}
        title="Delete"
        message="Are you sure you want to delete this Check List?"
        okText="Delete"
        cancelText="Cancel"
        onOk={() => {
          deleteCheckList();
          // setOpenDeleteConfirmation(false);
          // handleDelete(stage);
        }}
        onCancel={() => setOpenDeleteConfirmation(false)}
      />
      <BasicModal
        isOpen={openAddCheckListModal}
        onClose={() => {
          setOpenAddCheckListModal(false);
          //setReload(!reload)
          reset();
        }}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          // overflowY: "scroll",
          "@media (max-width: 768px)": {
            // Adjust the max-width as needed
            width: "80%", // Width for mobile devices
          },
        }}
        sx={modalStyle}
      >
        <>
          <div className="modal_header">
            <p className="modal_title ">Add Check List</p>
            <div className="modal_header_button_wrapper">
              <IconButton
                onClick={() => {
                  setOpenAddCheckListModal(false);
                  //setReload(!reload);
                  reset();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="modalbodyCheckListWrapper">
            <div className="modal_body">
              <div
                className="organisation"
                style={{ display: "block", padding: "0px" }}
              >
                <div className="">
                  <form noValidate onSubmit={handleSubmit(addCheckList)}>
                    <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <label className="text_medium">Check List Name</label>
                        <input
                          type="text"
                          name="name"
                          {...register("name", {
                            required: true,
                          })}
                        />
                        <p className="error_message">{errors.name?.message}</p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <label className="text_medium">
                          Check List Description
                        </label>
                        <textarea
                          type="text"
                          name="description"
                          rows={4}
                          {...register("description", {})}
                        />
                        <p className="error_message">
                          {errors.description?.message}
                        </p>
                      </Grid>
                      {renderItems()}
                    </Grid>
                    <div ref={bottomRef}></div>
                    <Button type="submit" className="primary_button marginT10">
                      Save
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* </form> */}
        </>
      </BasicModal>
      <BasicModal
        isOpen={openEditCheckListModal}
        onClose={() => {
          setOpenEditCheckListModal(false);
          setRemovedItems([]);
          resetEditForm();
        }}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          "@media (max-width: 768px)": {
            // Adjust the max-width as needed
            width: "80%", // Width for mobile devices
          },
          "@media (max-width: 768px)": {
            // Adjust the max-width as needed
            width: "80%", // Width for mobile devices
          },
        }}
        sx={modalStyle}
      >
        <>
          <div className="modal_header">
            <p className="modal_title ">Edit Check List</p>
            <div className="modal_header_button_wrapper">
              <IconButton
                onClick={() => {
                  setOpenEditCheckListModal(false);
                  setRemovedItems([]);
                  resetEditForm();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="modalbodyCheckListWrapper">
            <div className="modal_body">
              <div
                className="organisation"
                style={{ display: "block", padding: "0px" }}
              >
                <div className="">
                  <form
                    noValidate
                    onSubmit={handleSubmitEditForm(editCheckList)}
                  >
                    <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <label className="text_medium">Check List Name</label>
                        <input
                          type="text"
                          name="name"
                          {...registerEditForm("name", {
                            required: true,
                          })}
                        />
                        <p className="error_message">{errors.name?.message}</p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <label className="text_medium">
                          Check List Description
                        </label>
                        <textarea
                          type="text"
                          name="desc"
                          rows={4}
                          {...registerEditForm("desc", {})}
                        />
                        <p className="error_message">
                          {errors.description?.message}
                        </p>
                        <input
                          type="text"
                          name="statusid"
                          style={{ display: "none" }}
                          {...registerEditForm("statusid")}
                        />
                        <input
                          type="text"
                          name="chklistid"
                          style={{ display: "none" }}
                          {...registerEditForm("chklistid")}
                        />
                        <input
                          type="text"
                          name="isactive"
                          style={{ display: "none" }}
                          {...registerEditForm("isactive")}
                        />
                      </Grid>
                      {renderEditItems()}
                      {/* {fieldsEditForm.map((item, index) => {
                        return (
                          <>
                            <div key={item.id} className="item_title">
                              <div style={{ display: "flex" }}>
                                <div
                                  className="item_title"
                                  style={{
                                    paddingLeft: "16px",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    marginTop: "15px",
                                  }}
                                >
                                  Item {index + 1}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                  {" "}
                                  <IconButton
                                    sx={{
                                      backgroundColor: "transparent !important",
                                      //width:'20px',
                                      //height:'10px'
                                    }}
                                    // className={classes.customHoverFocus}

                                    aria-label="delete"
                                    onClick={() => {
                                      let itemRemoved =
                                        checkLists[currentValue].chklist_dtls[
                                          index
                                        ];
                                      if (itemRemoved) {
                                        let temp = {
                                          id: itemRemoved.chk_id,
                                          comments: itemRemoved.chk_comments,
                                          displayname:
                                            itemRemoved.chk_displayname,
                                          isactive: 0,
                                          isdefault: itemRemoved.chk_default,
                                          order: itemRemoved.chk_order,
                                          statusid: itemRemoved.chk_statusid,
                                        };
                                        setRemovedItems([
                                          ...removedItems,
                                          temp,
                                        ]);
                                      }
                                      removeEditForm(index);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </div>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <label className="text_medium">Item Name</label>
                                <input
                                  type="text"
                                  name="name"
                                  {...registerEditForm(`items.${index}.name`, {
                                    required: true,
                                  })}
                                />
                                <p className="error_message">
                                  {errors.name?.message}
                                </p>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <label className="text_medium">
                                  Item Description
                                </label>
                                <textarea
                                  type="text"
                                  name="comments"
                                  rows={4}
                                  {...registerEditForm(
                                    `items.${index}.comments`,
                                    {}
                                  )}
                                />
                                <input
                                  type="text"
                                  name="id"
                                  style={{ display: "none" }}
                                  {...registerEditForm(`items.${index}.id`)}
                                />
                                <input
                                  type="text"
                                  name="statusid"
                                  style={{ display: "none" }}
                                  {...registerEditForm(
                                    `items.${index}.statusid`
                                  )}
                                />
                                <input
                                  type="text"
                                  name="order"
                                  style={{ display: "none" }}
                                  {...registerEditForm(`items.${index}.order`)}
                                />
                                <input
                                  type="text"
                                  name="isdefault"
                                  style={{ display: "none" }}
                                  {...registerEditForm(
                                    `items.${index}.isdefault`
                                  )}
                                />
                                <input
                                  type="text"
                                  name="isactive"
                                  style={{ display: "none" }}
                                  {...registerEditForm(
                                    `items.${index}.isactive`
                                  )}
                                />
                                <p className="error_message">
                                  {errors.description?.message}
                                </p>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <label className="text_medium">
                                  Check List Type
                                </label>
                                <Controller
                                  name={`items.${index}.type`} // Use your appropriate index
                                  control={controlEditForm}
                                  // defaultValue={CHECKLIST_TYPE.TEXT} // Set a default value if needed
                                  rules={{ required: "This field is required" }} // Validation rules
                                  render={({ field: { onChange, value } }) => (
                                    <CustomDropDown
                                      options={[
                                        {
                                          id: "1",
                                          value: CHECKLIST_TYPE.TEXT,
                                          label: CHECKLIST_TYPE.TEXT,
                                        },
                                        {
                                          id: "2",
                                          value: CHECKLIST_TYPE.DROPDOWN,
                                          label: CHECKLIST_TYPE.DROPDOWN,
                                        },

                                        {
                                          id: "3",
                                          value: CHECKLIST_TYPE.CHECKBOX,
                                          label: CHECKLIST_TYPE.CHECKBOX,
                                        },
                                        {
                                          id: "4",
                                          value: CHECKLIST_TYPE.RADIO_BUTTON,
                                          label: CHECKLIST_TYPE.RADIO_BUTTON,
                                        },
                                        {
                                          id: "5",
                                          value:
                                            CHECKLIST_TYPE.MULTI_SELECT_DROP_DOWN,
                                          label:
                                            CHECKLIST_TYPE.MULTI_SELECT_DROP_DOWN,
                                        },
                                      ]}
                                      defaultValue={getEditformValues(
                                        `items.${index}.type`
                                      )}
                                      value={value} // Current value of the dropdown
                                      onChange={(value) => {
                                        setEditFormValue(
                                          `items.${index}.type`,
                                          value.value
                                        );
                                      }} // Handle value change
                                    />
                                  )}
                                />
                                {errors.items?.[index]?.type && (
                                  <p className="error_message">
                                    {errors.items[index].type.message}
                                  </p>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <label className="text_medium">
                                  Check if Mandatory
                                </label>
                                <Controller
                                  name={`items.${index}.isdefault`} 
                                  control={controlEditForm}
                                  defaultValue={
                                    getEditformValues(
                                      `items.${index}.isdefault`
                                    )
                                      ? true
                                      : false
                                  }
                                  render={({ field: { onChange, value } }) => (
                                    <Checkbox
                                      checked={
                                        getEditformValues(
                                          `items.${index}.isdefault`
                                        )
                                          ? true
                                          : false
                                      }
                                      sx={{
                                        color: "#cdd2e1",
                                        "&.Mui-checked": {
                                          color: "#189d3d",
                                        },
                                      }}
                                      onChange={(e) => {
                                        console.log(
                                          "value check box",
                                          e.target.checked
                                        );
                                        setEditFormValue(
                                          `items.${index}.isdefault`,
                                          e.target.checked
                                        );
                                      }}
                                      //value={value}
                                    />
                                  )}
                                />
                                {errors.items?.[index]?.type && (
                                  <p className="error_message">
                                    {errors.items[index].isdefault.message}
                                  </p>
                                )}
                              </Grid>
                            </div>
                          </>
                        );
                      })}
                      <div
                        className="addIcon"
                        onClick={(e) => {
                          appendEditForm({
                            id: 0,
                            statusid: status_id,
                            name: "",
                            displayname: "",
                            comments: "",
                            order: "",
                            isdefault: 0,
                            isactive: 1,
                            type: CHECKLIST_TYPE.TEXT,
                          });
                        }}
                      >
                        <div style={{ cursor: "pointer" }}>
                          {" "}
                          <IconButton
                            aria-label="adding"
                            onClick={(e) => {
                              e.preventDefault();
                              // append({
                              //   name: "",
                              //   description: "",
                              // });
                            }}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        </div>
                        <div
                          className="flexCenter"
                          style={{ cursor: "pointer" }}
                        >
                          Add item
                        </div>
                      </div> */}
                      {/* <div className="line" style={{marginTop:"10px"}}></div> */}
                    </Grid>
                    <div ref={bottomRef}></div>
                    <Button type="submit" className="primary_button marginT10">
                      Save
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* </form> */}
        </>
      </BasicModal>
    </CardItem>
  );
};

export default WorkFlowChecklist;
