import { all } from "redux-saga/effects";
import { authSagas } from "./authSagas";
import { projectSagas } from "./projectSaga";
import { orgSagas } from "./orgSagas";
import { leadSagas } from "./leadSagas";
import { appointmentSagas } from "./appointmentSagas";
import { fileUploadSagas } from "./fileUploadSagas";
import { commentSagas } from "./commentSagas";
import { feedbackSagas } from "./feedbackSagas";
import { MaterialSagas } from "./materialSagas";
import { profileSagas } from "./profileSaga";
import { userSagas } from "./userSagas";
import { groupSagas } from "./groupSaga";
import { additionalInfoSagas } from "./additionalInfoSagas";
import { checklistSagas } from "./checklistSagas";

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...projectSagas,
    ...orgSagas,
    ...leadSagas,
    ...appointmentSagas,
    ...fileUploadSagas,
    ...commentSagas,
    ...feedbackSagas,
    ...MaterialSagas,
    ...profileSagas,
    ...userSagas,
    ...groupSagas,
    ...additionalInfoSagas,
    ...checklistSagas,
  ]);
}
