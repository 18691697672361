import { useDispatch, useSelector } from "react-redux";
import Button from "../../../buttons/Button";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import {
  getProjectStageList,
  // updateProjectStatus,
} from "../../../../redux/slices/projectSlice";
import Storage from "../../../../utils/Storage";
import { useParams } from "react-router-dom";
import CustomDialog from "../../../dialog/CustomDialog";
import { useEffect, useState } from "react";
import { green, grey, red } from "@mui/material/colors";
import { Delete } from "@mui/icons-material";
import ChecklistIcon from "@mui/icons-material/Checklist";
import Status from "../../../status/Status";
import Loading from "../../../loading/Loading";
import CheckList from "../checklist/CheckList";
import BasicModal from "../../../modal/Modal";
import { modalStyle } from "../../../constants/ConstData";
import { setCreateSnackbar } from "../../../../redux/slices/formSlice";
import { useDialog } from "../../../../hooks/useDialog";
import {
  getProjectStatus,
  updateProjectStatus,
} from "../../../../redux/slices/additionalInfoSlice";

const ProjectMultipleStatus = ({
  handleMultiple,
  loadProjectStatusList,
  onClose,
}) => {
  const projectData = useSelector((state) => state.project.projectData);
  const statusLoading = useSelector((state) => state.project.statusLoading);
  const projectStatusList = useSelector(
    (state) => state.project.projectStatusList
  );
  const projectStageList = useSelector(
    (state) => state.project.projectStageList
  );
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [anchorElStage, setAnchorElStage] = useState(null);
  const [statusSelectedId, setStatusSelectedId] = useState();
  const [stageStatus, setStageStatus] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const stageLoading = useSelector((state) => state.project.stageLoading);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const { id } = useParams();
  const loading = useSelector((state) => state.project.loading);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const {
    isOpen,
    openDialog,
    closeDialog,
    title,
    content,
    okLabel,
    cancelLabel,
    handleOk,
    handleCancel,
  } = useDialog();
  const createData = useSelector((state) => state.form.createData);

  const projectStatusLoading = useSelector(
    (state) => state.additionalInfo.projectStatusLoading
  );

  const projectStatus = useSelector(
    (state) => state.additionalInfo.projectStatus
  );

  const snackbarData = useSelector((state) => state.form.snackbarData);
  const addInfoloading = useSelector((state) => state.additionalInfo.loading);

  const isOwner =
    Storage.read(`org${userInfo?.id}`)?.isOwner === "true" ? true : false;
  const isModerator =
    Storage.read(`org${userInfo?.id}`)?.isModerator === "true" ? true : false;

  const dispatch = useDispatch();

  const handleStatus = (event, statusId) => {
    event.preventDefault();
    setAnchorElStatus(event.currentTarget);
    setStatusSelectedId(statusId);
  };

  const handleStage = (event, status) => {
    event.preventDefault();
    setAnchorElStage(event.currentTarget);
    setStageStatus(status);

    dispatch(
      getProjectStageList({
        url: `crm/org/${orgId}/proj/status/${status?.statusId}/stages`,
        params: {},
      })
    );
  };

  const deleteStatus = (stsId) => {
    openDialog({
      newTitle: "Delete Status",
      newContent: "Are you sure, you want delete this status ?",
      okLabel: "Ok, delete",
      cancelLabel: "Cancel",
      onOk: () => handleSuccess(stsId),
      onCancel: handleCancel,
    });
  };

  const handleCloseStatusMenu = () => {
    setAnchorElStatus(null);
    setAnchorElStage(null);
  };

  const handleSuccess = (stId) => {
    dispatch(
      updateProjectStatus({
        url: `crm/org/${orgId}/proj/${id}/status/${stId}/close`,
        data: {},
        params: { data: "status" },
      })
    );
    setDialogOpen(false);
  };

  // const cancelDialog = () => {
  //   setDialogOpen(false);
  // };

  const handleCheckList = (status) => {
    setSelectedStatus(status);

    setIsViewOpen(true);
  };

  const handleClose = () => {
    setIsViewOpen(false);
    setSelectedStatus();
  };

  useEffect(() => {
    if (anchorElStatus) {
      loadProjectStatusList();
    }
  }, [anchorElStatus]);

  const loadAdditionalInfo = () => {
    dispatch(
      getProjectStatus({
        url: `/crm/org/${orgId}/proj/${id}/info?data=status`,
        params: {},
      })
    );
  };

  useEffect(() => {
    if (
      snackbarData?.createSuccess &&
      snackbarData?.params?.data === "status"
    ) {
      console.log("params=----------->", snackbarData?.params);
      // handleClose();
      loadAdditionalInfo();
      onClose();
    }
  }, [snackbarData]);

  if (projectStatusLoading || addInfoloading) {
    return <Loading />;
  }

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
      className=""
    >
      {isOwner | isModerator ? (
        <Button
          onClick={handleMultiple}
          className="icon_button marginL10 d_flex align_items_center primary_bg text_white"
        >
          <AddIcon className="btn_icon" />
          Add Multiple Status
        </Button>
      ) : null}

      {projectStatus && (
        <div className="marginL20">
          <p>Status</p>
          {projectStatus?.map((st, index) => (
            <div
              key={index}
              style={{
                border: "1px dashed rgb(24, 157, 61) ",
                padding: "10px",
              }}
              className="d_flex  align_items_center"
            >
              <Button
                style={{
                  cursor: "pointer",
                  border: "1px dashed rgb(24, 157, 61) ",
                  borderRadius: "20px",
                  padding: "5px 10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  backgroundColor: "transparent",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
                onClick={(event) => handleStatus(event, st.statusId)}
                disabled={isOwner | isModerator ? false : true}
              >
                <p style={{ fontSize: "14px", margin: 0 }}>{st?.statusName}</p>
                <ArrowDropDownIcon />
              </Button>
              {!statusLoading && (
                <Menu
                  sx={{ mt: "30px" }}
                  style={{ height: "400px" }}
                  id="menu-appbar"
                  anchorEl={anchorElStatus}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElStatus)}
                  onClose={handleCloseStatusMenu}
                >
                  {projectStatusList?.map((status, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        // setStatus(status);
                        // setValue("statusId", status?.id);
                        if (st.checklist_status === "Checklist Completed") {
                          openDialog({
                            newTitle: "Update Status",
                            newContent: `Are you sure, you want to move this project from ${st?.statusName} to ${status?.name} ?`,
                            okLabel: "Ok, Update",
                            cancelLabel: "Cancel",
                            onOk: () => {
                              const statusData = [
                                {
                                  customerInfo: projectData?.customerInfo,
                                  address: projectData?.address,
                                  prevStatusId: statusSelectedId,
                                  status: {
                                    id: status?.id,
                                    name: status?.name,
                                    uid: status?.uid,
                                    userName: status?.userName,
                                  },
                                  assignedUser:
                                    status?.users?.length > 0
                                      ? status?.users?.map((user) => user?.id)
                                      : [0],
                                  groupId:
                                    status?.groups?.length > 0
                                      ? status?.groups?.map(
                                          (group) => group?.id
                                        )
                                      : [0],
                                },
                              ];

                              dispatch(
                                updateProjectStatus({
                                  url: `crm/org/${orgId}/proj/${id}/status`,
                                  data: statusData,
                                  params: { data: "status" },
                                })
                              );

                              handleCloseStatusMenu();
                            },
                            onCancel: handleCancel,
                          });

                          console.log(
                            "CheckStatus=----------->",
                            st.checklist_status
                          );
                        } else {
                          dispatch(
                            setCreateSnackbar({
                              message:
                                "You have some pending checklist for this status. Please update the checklist and try again",
                              createSuccess: true,
                              statusCode: 600,
                            })
                          );
                        }
                      }}
                      disabled={projectStatus?.find(
                        (dis) => dis?.statusId === status?.id
                      )}
                    >
                      <Typography textAlign="center">
                        {status?.displayName}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              )}
              {st?.stageName && (
                <div style={{ width: "50%" }} className="marginL20 ">
                  <Button
                    style={{
                      cursor: "pointer",
                      border: "1px dashed rgb(24, 157, 61)",
                      borderRadius: "20px",
                      padding: "2px 5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      backgroundColor: "#37906b14",
                      color: "#189D3D",
                    }}
                    onClick={(event) => handleStage(event, st)}
                    // disabled={true}
                    disabled={isOwner | isModerator ? false : true}
                  >
                    <p style={{ fontSize: "12px", margin: 0 }}>
                      {st?.stageName}
                    </p>
                    <ArrowDropDownIcon />
                  </Button>
                  {!stageLoading && (
                    <Menu
                      sx={{ mt: "30px" }}
                      style={{ height: "400px" }}
                      id="menu-appbar"
                      anchorEl={anchorElStage}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElStage)}
                      onClose={handleCloseStatusMenu}
                    >
                      {projectStageList?.map((stage, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            // setStatus(stage);
                            // setValue("statusId", status?.id);
                            // const stageData = {
                            //   prevStatusId: stageStatus.statusId,
                            //   status: {
                            //     id: stageStatus.statusId,
                            //     name: stageStatus.statusName,
                            //   },
                            //   stage: {
                            //     id: stage?.id,
                            //     name: stage?.name,
                            //   },
                            // };

                            const stageData = [
                              {
                                customerInfo: projectData?.customerInfo,
                                address: projectData?.address,
                                prevStatusId: stageStatus.statusId,
                                status: {
                                  id: stageStatus.statusId,
                                  name: stageStatus.statusName,
                                },
                                stage: {
                                  id: stage?.id,
                                  name: stage?.name,
                                },
                                assignedUser:
                                  stageStatus?.users?.length > 0
                                    ? stageStatus?.users?.map(
                                        (user) => user?.id
                                      )
                                    : [0],
                                groupId:
                                  stageStatus?.groups?.length > 0
                                    ? stageStatus?.groups?.map(
                                        (group) => group?.id
                                      )
                                    : [0],
                              },
                            ];

                            dispatch(
                              updateProjectStatus({
                                url: `crm/org/${orgId}/proj/${id}/status`,
                                data: stageData,
                                params: { data: "status" },
                              })
                            );
                            handleCloseStatusMenu();
                          }}
                        >
                          <Typography textAlign="center">
                            {stage?.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </div>
              )}
              {st?.checklist?.length > 0 && (
                <IconButton
                  disabled={isOwner | isModerator ? false : true}
                  onClick={() => handleCheckList(st)}
                >
                  <ChecklistIcon
                    sx={{
                      color: isOwner | isModerator ? green[500] : grey[500],
                    }}
                  />
                </IconButton>
              )}

              {projectStatus?.length > 1 && (
                <IconButton
                  disabled={isOwner | isModerator ? false : true}
                  onClick={() => deleteStatus(st.statusId)}
                >
                  <Delete
                    sx={{ color: isOwner | isModerator ? red[500] : grey[500] }}
                  />
                </IconButton>
              )}
            </div>
          ))}
        </div>
      )}
      <CustomDialog
        open={isOpen}
        title={title}
        message={content}
        okText={okLabel}
        cancelText={cancelLabel}
        onOk={handleOk}
        onCancel={handleCancel}
      />
      <BasicModal
        isOpen={isViewOpen}
        onClose={handleClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "50%", md: "40%", lg: "30%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "420px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <CheckList selectedStatus={selectedStatus} onClose={handleClose} />
      </BasicModal>
    </div>
  );
};

export default ProjectMultipleStatus;
