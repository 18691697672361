export const modalStyle = {
  ".modal_header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 24px",
    borderBottom: "1px solid #ebebeb",
  },
  ".modal_header .modal_title": {
    color: "#151515",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "125%",
  },
  ".modal_body": {
    padding: "32px 24px",
    marginBottom: "20px",
    height: "70%",
    overflowY: "scroll",
  },
  ".new_project_name": {
    color: "#75787D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "125%",
  },
  ".assign_details_title": {
    color: "#151515",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "160%",
  },
  ".project_assign_details": {
    borderRadius: "12px",
    border: "1px solid  #EBEBEB",
    background: "#FFF",
  },
  ".modal_footer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 24px",
    borderTop: "1px solid #ebebeb",
    // position: "fixed",
    background: "#e5f6db",
    // width: "auto-webkit-fill-available",
    position: "fixed",
    bottom: 0,
    width: "-webkit-fill-available",
  },
};
