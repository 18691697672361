import { Avatar, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { stringAvatar } from "../../utils/util";
import { Edit } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { CardItem } from "../../utils/CardItem";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getOrgList,
  getOrganisationDetails,
} from "../../redux/slices/orgSlice";
import { useDispatch } from "react-redux";
import BasicModal from "../../components/modal/Modal";
import { modalStyle } from "../../components/constants/ConstData";
import { singleFileUploadSuccess } from "../../redux/slices/fileUploadSlice";
import FileUploadForm from "../../components/project/view/fileupload/FileUploadForm";
import './AdminSidebar.scss'
const LINKS = {
  USER_LINK: {
    INDEX: "Users",
    ALL: "All",
    INVITES: "Invites",
    MODERATORS: "Moderators",
    ROLES:'Roles'
  },
  PROJECTS_LINK: "Projects",
  LEADS_LINK: "Leads",
  WORKFLOW_LINK: {
    INDEX: "WorkFlow",
    MANAGE_STATUS: "Manage Status",
    MULTIPLE_ASSIGNE: "Multiple Assigne",
    CHECK_LIST:"checklist"
  },
  GROUPS: "Groups",
  SETTINGS_LINK: {
    INDEX: "Settings",
    // GROUPS: "Groups",
    // INVITE_PEOPLE: "Invite People",
    EDIT_ORGANISATION: "Edit Organization",
    // MANAGE_STATUS: "Manage Status",
    MANAGE_MATERIALS: "Manage Materials",
    CHECK_LIST: "Check List",
    // TRANSFER_OWNERSHIP: "Transfer Ownership",
  },
};
const AdminSidbar = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  console.log("location.pathname", location.pathname.split("/")[3]);
  const { orgName, email } = location.state || {};
  const [isHovered, setIsHovered] = useState(false);
  const organisationDetails = useSelector(
    (state) => state.org.organisationDetails
  );
  const [isOpen, setIsOpen] = useState(false);
  const singleUpSuccess = useSelector((state) => state.upload.singleUpSuccess);

  const [selectedLink, setSelectedLink] = useState(LINKS.USER_LINK.INDEX);
  const [selectedSublink, setSelectedSublink] = useState(LINKS.USER_LINK.ALL);
  const handleClose = () => {
    setIsOpen(false);
    dispatch(singleFileUploadSuccess(false));
  };
  const changeSubLink = (parentLink, sublink) => {
    if (parentLink !== selectedLink) return;
    setSelectedSublink(sublink);
  };
  const changeLink = (link) => {
    setSelectedLink(link);
  };
  const loadOrganisationDetails = () => {
    dispatch(
      getOrganisationDetails({
        url: `/crm/user/organization/${id}`,
        params: { orgId: id },
      })
    );
  };
  useEffect(() => {
    loadOrganisationDetails();
  }, []);
  useEffect(() => {
    if (singleUpSuccess) {
      handleClose();
      loadOrganisationDetails();
      dispatch(getOrgList({ url: "crm/user/organization", params: {} }));
    }
  }, [singleUpSuccess]);
  return (
    <>
      <CardItem height="810px">
        <div
          className="d_flex flex_column align_items_center justify_content_center"
          style={{ paddingTop: "20px" }}
        >
          <div
            className="orgprofile_image_container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {organisationDetails?.logodtls ? (
              <img
                src={organisationDetails?.logodtls}
                alt="Organization"
                className="orgprofile_image"
                loading="lazy"
              />
            ) : (
              <Avatar
                {...stringAvatar(
                  organisationDetails?.name
                    ? organisationDetails.name
                    : "organisation name"
                )}
                sx={{ bgcolor: "#388e3c" }}
                className="orgprofile_image"
              />
            )}

            {isHovered && (
              <div className="edit_overlay">
                <IconButton
                  className="edit_button"
                  size="small"
                  color="white"
                  onClick={() => setIsOpen(true)}
                >
                  <Edit />
                </IconButton>
              </div>
            )}
          </div>
          {/* {organisationDetails?.logodtls ? (
                <img
                  style={{ width: "56px", height: "56px", borderRadius: "50%" }}
                  src={organisationDetails?.logodtls}
                  alt="Organisation"
                />
              ) : (
                <Avatar
                  {...stringAvatar(
                    organisationDetails?.name
                      ? organisationDetails.name
                      : orgName
                  )}
                  sx={{ width: 56, height: 56, bgcolor: "#388e3c" }}
                />
              )} */}

          {/* </IconButton> */}
          <h2>
            {organisationDetails?.name ? organisationDetails.name : orgName}
          </h2>
          <div className="" style={{ width: "100%" }}>
            <div className="d_flex email_div" style={{ width: "100%",justifyContent:'center' }}>
              <strong>Email:</strong>
              <span className="marginL10 email_wrapper">
                {organisationDetails?.email ? organisationDetails.email : email}
       
              </span>
            </div>
          </div>
          <div style={{ width: "85%",marginTop:'10px' }}>
            <div
              className={
                //selectedLink == LINKS.USER_LINK.INDEX
                location.pathname.split("/")[3] === "users" ||
                location.pathname.split("/")[3] === "invites" ||
                location.pathname.split("/")[3] === "moderators" ||
                location.pathname.split("/")[3] === "roles"

                  ? "organisation_link_box_active"
                  : "organisation_link_box"
              }
              onClick={() => {
                // setProjectStatusDetailsCard(false);
                // setShowGroupDetailsCard(false);
                // setShowProjectStatusCard(false);
                // setShowCheckListCard(false);
                setSelectedSublink(LINKS.USER_LINK.ALL);
                changeLink(LINKS.USER_LINK.INDEX);
                navigate(`/org/${id}/users`);
              }}
            >
              <h3 className="organisation_link">Users</h3>
            </div>
            {(location.pathname.split("/")[3] === "users" ||
              location.pathname.split("/")[3] === "invites" ||
              location.pathname.split("/")[3] === "moderators" ||
                 location.pathname.split("/")[3] === "roles"
            
            ) && (
              <Grid container>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <div></div>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <div style={{ width: "100%" }}>
                    <div className="">
                      <h4
                        className={
                          //  selectedSublink === LINKS.USER_LINK.ALL
                          location.pathname.split("/")[3] === "users"
                            ? "organisation_link_active"
                            : "organisation_link"
                        }
                        onClick={() => {
                          changeSubLink(
                            LINKS.USER_LINK.INDEX,
                            LINKS.USER_LINK.ALL
                          );

                          navigate(`/org/${id}/users`);
                        }}
                      >
                        All Users
                      </h4>
                    </div>
                    <div>
                      <h4
                        className={
                          //  selectedSublink === LINKS.USER_LINK.MODERATORS
                          location.pathname.split("/")[3] === "moderators"
                            ? "organisation_link_active"
                            : "organisation_link"
                        }
                        onClick={() => {
                          changeSubLink(
                            LINKS.USER_LINK.INDEX,
                            LINKS.USER_LINK.MODERATORS
                          );
                          navigate(`/org/${id}/moderators`);
                        }}
                      >
                        Moderators
                      </h4>
                    </div>
                    <div>
                      <h4
                        className={
                          //  selectedSublink === LINKS.USER_LINK.INVITES
                          location.pathname.split("/")[3] === "invites"
                            ? "organisation_link_active"
                            : "organisation_link"
                        }
                        onClick={() => {
                          navigate(`/org/${id}/invites`);
                          changeSubLink(
                            LINKS.USER_LINK.INDEX,
                            LINKS.USER_LINK.INVITES
                          );
                        }}
                      >
                        Invites
                      </h4>
                    </div>
                    {/* <div>
                      <h4
                        className={
                          //  selectedSublink === LINKS.USER_LINK.INVITES
                          location.pathname.split("/")[3] === "roles"
                            ? "organisation_link_active"
                            : "organisation_link"
                        }
                        onClick={() => {
                          navigate(`/org/${id}/roles`);
                          changeSubLink(
                            LINKS.USER_LINK.INDEX,
                            LINKS.USER_LINK.ROLES
                          );
                        }}
                      >
                        Roles
                      </h4>
                    </div> */}
                  </div>
                </Grid>
              </Grid>
            )}
            <div
              onClick={() => {
                // setShowProjectStatusCard(false);
                // setShowGroupDetailsCard(false);
                // setProjectStatusDetailsCard(false);
                // setShowCheckListCard(false);

                changeLink(LINKS.GROUPS);
                navigate(`/org/${id}/groups`);
              }}
              className={
                // selectedLink == LINKS.GROUPS
                location.pathname.split("/")[3] === "groups"
                  ? "organisation_link_box_active"
                  : "organisation_link_box"
              }
            >
              <h3 className="organisation_link">Groups</h3>
            </div>
            <div
              onClick={() => {
                // setShowGroupDetailsCard(false);
                // setProjectStatusDetailsCard(false);
                changeLink(LINKS.PROJECTS_LINK);
                navigate(`/org/${id}/projects`);

                // setShowProjectStatusCard(false);
              }}
              className={
                // selectedLink == LINKS.PROJECTS_LINK
                location.pathname.split("/")[3] === "projects"
                  ? "organisation_link_box_active"
                  : "organisation_link_box"
              }
            >
              <h3 className="organisation_link">Projects</h3>
            </div>
            <div
              onClick={() => {
                // setShowGroupDetailsCard(false);
                // setProjectStatusDetailsCard(false);
                // setShowProjectStatusCard(false);
                // setShowCheckListCard(false);
                changeLink(LINKS.LEADS_LINK);
                navigate(`/org/${id}/leads`);
              }}
              className={
                //selectedLink == LINKS.LEADS_LINK
                location.pathname.split("/")[3] === "leads"
                  ? "organisation_link_box_active"
                  : "organisation_link_box"
              }
            >
              <h3 className="organisation_link">Leads</h3>
            </div>
            <div
              onClick={() => {
                // setShowProjectStatusCard(true);
                // setShowGroupDetailsCard(false);
                // setProjectStatusDetailsCard(false);
                setSelectedSublink("");
                changeLink(LINKS.WORKFLOW_LINK.INDEX);
                navigate(`/org/${id}/workflow`);

                //  setShowCheckListCard(false);
                // changeSubLink(
                //   LINKS.WORKFLOW_LINK.INDEX,
                //   LINKS.WORKFLOW_LINK.MANAGE_STATUS
                // );
              }}
              className={
                //  selectedLink == LINKS.WORKFLOW_LINK.INDEX
                location.pathname.split("/")[3] === "workflow"
                  ? "organisation_link_box_active"
                  : "organisation_link_box"
              }
            >
              <h3 className="organisation_link">Work Flow</h3>
            </div>
            {location.pathname.split("/")[3] === "workflow" && (
              <Grid container>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <div></div>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <div>
                    {/* <div>
                          <h4
                            className={
                              selectedSublink ===
                              LINKS.WORKFLOW_LINK.MANAGE_STATUS
                                ? "organisation_link_active"
                                : "organisation_link"
                            }
                            setProjectDetailsStatusToShow
                            onClick={() => {
                              setProjectStatusDetailsCard(false);
                              setShowProjectStatusCard(false);
                              changeSubLink(
                                LINKS.WORKFLOW_LINK.INDEX,
                                LINKS.WORKFLOW_LINK.MANAGE_STATUS
                              );
                            }}
                          >
                            Default Assigne
                          </h4>
                        </div> */}
                    <div>
                      <h4
                        className={
                          //  selectedSublink ===
                          // LINKS.WORKFLOW_LINK.MULTIPLE_ASSIGNE
                          location.pathname.split("/")[4] === "multiple_assigne"
                            ? "organisation_link_active"
                            : "organisation_link"
                        }
                        setProjectDetailsStatusToShow
                        onClick={() => {
                          // setProjectStatusDetailsCard(false);
                          // setShowProjectStatusCard(false);
                          // setShowCheckListCard(false);
                          changeSubLink(
                            LINKS.WORKFLOW_LINK.INDEX,
                            LINKS.WORKFLOW_LINK.MULTIPLE_ASSIGNE
                          );
                          navigate(`/org/${id}/workflow/multiple_assigne`);
                        }}
                      >
                        Multiple Assignee
                      </h4>
                    </div>
                    {/* <div>
                      <h4
                        className={
                          location.pathname.split("/")[4] === "checklist"
                            ? "organisation_link_active"
                            : "organisation_link"
                        }
                        onClick={() => {               
                          changeSubLink(
                            LINKS.WORKFLOW_LINK.INDEX,
                            LINKS.WORKFLOW_LINK.CHECK_LIST
                          );
                          navigate(`/org/${id}/workflow/checklist`);
                        }}
                      >
                        Check List
                      </h4>
                    </div> */}
                  </div>
                </Grid>
              </Grid>
            )}
            <div
              onClick={() => {
                // setShowProjectStatusCard(false);
                // setShowGroupDetailsCard(false);
                // setProjectStatusDetailsCard(false);
                // setShowCheckListCard(false);
                setSelectedSublink(LINKS.SETTINGS_LINK.EDIT_ORGANISATION);
                changeLink(LINKS.SETTINGS_LINK.INDEX);
                changeSubLink(
                  LINKS.SETTINGS_LINK.INDEX,
                  LINKS.SETTINGS_LINK.EDIT_ORGANISATION
                );
                navigate(`/org/${id}/settings`);
              }}
              className={
                location.pathname.split("/")[3] === "settings"
                  ? //   selectedLink == LINKS.SETTINGS_LINK.INDEX
                    "organisation_link_box_active"
                  : "organisation_link_box"
              }
            >
              <h3 className="organisation_link">Settings</h3>
            </div>
            {location.pathname.split("/")[3] === "settings" && (
              <Grid container>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <div></div>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <div>
                    <div>
                      <h4
                        className={
                          location.pathname.split("/")[3] === "settings"
                            ? "organisation_link_active"
                            : "organisation_link"
                        }
                        onClick={() =>
                          changeSubLink(
                            LINKS.SETTINGS_LINK.INDEX,
                            LINKS.SETTINGS_LINK.EDIT_ORGANISATION
                          )
                        }
                      >
                        Edit Organization
                      </h4>
                    </div>
{/* 
                    <div>
                      <h4
                        className={
                          selectedSublink ===
                          LINKS.SETTINGS_LINK.MANAGE_MATERIALS
                            ? "organisation_link_active"
                            : "organisation_link"
                        }
                        onClick={() =>{
                          changeSubLink(
                            LINKS.SETTINGS_LINK.INDEX,
                            LINKS.SETTINGS_LINK.MANAGE_MATERIALS
                          )
                          navigate(`/org/${id}/settings/manage_materiails`)
                        }
                        
                        }
                      >
                        Manage Materials
                      </h4>
                    </div> */}

                    {/* <div>
                          <h4
                            className={
                              selectedSublink === LINKS.SETTINGS_LINK.CHECK_LIST
                                ? "organisation_link_active"
                                : "organisation_link"
                            }
                            onClick={() =>
                              changeSubLink(
                                LINKS.SETTINGS_LINK.INDEX,
                                LINKS.SETTINGS_LINK.CHECK_LIST
                              )
                            }
                          >
                            Check List
                          </h4>
                        </div> */}
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
        <BasicModal
          boxStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            height: "500px",
            display: "flex",
            flexDirection: "column",
          }}
          isOpen={isOpen}
          onClose={handleClose}
          sx={modalStyle}
        >
          <FileUploadForm
            onClose={handleClose}
            title={"Organization Logo"}

            // type={uploadDetails?.type}
            // typeId={uploadDetails?.typeId}
            // folderId={uploadDetails?.folderId}
          />
        </BasicModal>
      </CardItem>
    </>
  );
};

export default AdminSidbar;
